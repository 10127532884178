<template>
  <FormControl
    :title="title"
    :sub-title="subTitle"
    :label="label"
    :disabled="disabled">
    <div
      class="input flex gap-1 relative"
      :class="`${inputClasses} ${wrapContent ? 'flex-wrap' : ''}`">
      <div
        v-if="$slots.prefix"
        class="flex items-center pointer-events-none overflow-hidden">
        <slot name="prefix" />
      </div>
      <div v-if="$slots.actions" class="flex items-center overflow-hidden">
        <slot name="actions" />
      </div>
      <input
        v-bind="$attrs"
        v-model="model"
        :disabled="disabled"
        :placeholder="placeholder"
        class="grow border-0 bg-transparent p-0 flex-1"
        :type="type"
        :maxlength="maxlength"/>
      <div v-if="maxlength">{{ (model as string).length }}/{{ maxlength }}</div>
      <div v-if="$slots.suffix" class="flex items-center overflow-hidden">
        <slot name="suffix"></slot>
      </div>
    </div>
    <p
      v-if="$slots.tip"
      class="mt-2 text-sm text-secondary-500 dark:text-secondary-400">
      <slot name="tip" />
    </p>
  </FormControl>
</template>
<script lang="ts" setup>
import type { PropType } from 'vue';
import type { InputSize, InputVariant } from '@/components/Input/types';
import { useInputClasses } from '@/components/Input/composables/useInputClasses';
import { toRefs } from 'vue';
import { useVModel } from '@vueuse/core';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
    usage: 'developer',
  },
  type: {
    type: String as PropType<
      | 'button'
      | 'checkbox'
      | 'color'
      | 'date'
      | 'datetime-local'
      | 'email'
      | 'file'
      | 'hidden'
      | 'image'
      | 'month'
      | 'number'
      | 'password'
      | 'radio'
      | 'range'
      | 'reset'
      | 'search'
      | 'submit'
      | 'tel'
      | 'text'
      | 'time'
      | 'url'
      | 'week'
    >,
    default: 'text',
    usage: 'developer',
  },
  placeholder: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<InputSize>,
    default: 'md',
    usage: 'developer',
  },
  variant: {
    type: String as PropType<InputVariant>,
    default: 'default',
    usage: 'developer',
  },
  error: {
    type: Boolean,
    default: false,
  },
  wrapContent: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    default: '',
    usage: 'developer',
  },
  maxlength: {
    type: [Number],
    default: null,
  },
});

const model = useVModel(props, 'modelValue');
const { inputClasses } = useInputClasses(toRefs(props));
</script>
